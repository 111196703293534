import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import TM47Page from "./pages/thailand/TM47Page";
import ExtendTouristVisaPage from "./pages/thailand/ExtendTouristVisaPage";
// Use React.lazy() for lazy loading
const HomePage = React.lazy(() => import('./pages/HomePage'));
const VisaExtensionPage = React.lazy(() => import('./pages/thailand/VisaExtensionPage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const TM7Page = React.lazy(() => import('./pages/thailand/TM7Page'));
// const TM47Page = React.lazy(() => import('./pages/thailand/TM47Page'));
const ContactUsPage = React.lazy(() => import('./pages/ContactUsPage'));
const STM2Page = React.lazy(() => import('./pages/thailand/STM2Page'));
const Form30Page = React.lazy(() => import('./pages/thailand/Form30Page'));
const FAQPage = React.lazy(() => import('./pages/thailand/FAQPage'));
const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/visa-extension" element={<VisaExtensionPage />} />
          <Route path="/tm7" element={<TM7Page />} />
          <Route path="/tm47" element={<TM47Page />} />
          <Route path="/stm2" element={<STM2Page />} />
          <Route path="/Acknowledgement-of-Overstay" element={<Form30Page />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/frequently-asked-questions" element={<FAQPage />} />
             <Route path="/thailand-extend-tourist-visa" element={<ExtendTouristVisaPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
