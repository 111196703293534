import React from 'react';

function Header() {
  return (
    <header className="bg-blue-500 text-white" >
         <div className="header-container mx-4 md:mx-10 lg:mx-20 xl:mx-40 2xl:mx-50 flex justify-between items-center">
      <a href="/"> <h1 className="text-2xl px-5 py-5">ClipBond</h1></a>
        <nav>
          <ul className="flex space-x-1">
            <li>
              <a href="/" className="hover:bg-blue-700 p-2 rounded">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="hover:bg-blue-700 p-2 rounded">
                About
              </a>
            </li>
                 <li>
              <a href="/contactus" className="hover:bg-blue-700 p-2 rounded">
                Contact
              </a>
            </li>
            {/*      <li>*/}
            {/*  <a href="/frequently-asked-questions" className="hover:bg-blue-700 p-2 rounded">*/}
            {/*    FaQ*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
