import React from 'react';
import {Helmet} from "react-helmet";
// import TM47Form from "../../components/thailand/TM47Form";

const TM47Form = React.lazy(() => import('../../components/thailand/TM47Form'));

const TM47Page = () => {
    return (
        <div>
             <Helmet>
                <title>Thailand 90 days report</title>
                <meta name="description" content="Thailand 90 days report, Thailand Visa, Thailand Visa Requirements" />
                {/* Add more meta tags if needed */}
            </Helmet>
        <div className="grid md:flex mt-4 justify-center body-content " >

            <div className="md:w-1/4 px-4 ">
                {/* Content column */}

                <div className="p-4 rounded bg-gray-100">
                                    <div className="text-right">Last Update : 30 May 2024</div>
                    {/* Your content here */}
<div className="container mx-auto py-2 ">
        <h1 className="text-2xl font-bold mb-4">TM 47</h1>
        <ul className="list-disc pl-8">
            <li className="mb-2">Form for alien to notify of staying longer than 90 days</li>
        </ul>
    </div>


                         <div className="container mx-auto py-4">
    <p>Note: This website or form will not store any information and is not affiliated with any government in Thailand. It is only intended to help fill out the form.</p>
</div>

                </div>
            </div>
            <div className="md:w-1/2 px-4 my-2">
                {/* Form column */}
                <div className="bg-blue-200 p-2 rounded">
                    {/* Your form component here */}
                    <TM47Form />
                </div>
            </div>
        </div>
               </div>
    );
};
export default TM47Page;