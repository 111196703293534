import React from 'react';
import {Helmet} from "react-helmet";
import { QuestionMarkCircleIcon   } from '@heroicons/react/24/solid';

const ExtendTouristVisaPage = () => {
    return (
       <div className="container mx-auto py-8">
      <div className="max-w-2xl mx-auto">
    <div className="flex items-center mb-4">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">How to extend Thailand tourist Visa
          </h2>
          <QuestionMarkCircleIcon className="h-6 w-6 text-blue-500 mb-3" aria-hidden="true" />
        </div>

             <div className="container mx-auto py-2 ">
        <h1 className="text-2xl font-bold mb-4">What You Need for Visa Extension</h1>
        <ul className="list-disc pl-8">
            <li className="mb-2">Your passport</li>
            <li className="mb-2">1,900 Baht</li>
            <li className="mb-2">Completed application form (TM7,stm2,Acknowledgement-of-Overstay)</li>
            <li className="mb-2">1 passport photo (3.5 cm x 4.5 cm)</li>
            <li className="mb-2">1 photocopy of the main page of your passport</li>
            <li className="mb-2">1 photocopy of the page showing current visa stamp</li>
            <li className="mb-2">1 copy of the TM30 registration – Your accommodation is required to register you online with Thai Immigration. This is called a TM30. Ask the staff / owner to print off the proof that they have registered you</li>
        </ul>
    </div>

        <div className="container mx-auto py-2 ">
        <h1 className="text-2xl font-bold mb-4">Procedure </h1>
        <ul className="list-disc pl-8">
        <li className="mb-2">Once you complete the form</li>
        <li className="mb-2">Go to Counter </li>
        </ul>
        </div>


             <div className="container mx-auto py-2 ">
        <h1 className="text-2xl font-bold mb-4">Location in Bangkok </h1>
        <ul className="list-disc pl-8">
        <li className="mb-2">Immigration Division 1 (Chang Watthana) map https://maps.app.goo.gl/qoVqgi4apdyuEm218</li>
        <li className="mb-2">Immigration Office - Short-term VISA (Laksi IT Square 4th floor)  </li>
        </ul>
        </div>

      </div>
    </div>
    );
};
export default ExtendTouristVisaPage;